import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import mainImage from "../img/matthias.png";

export const IndexPageTemplate = () => (
  <Container style={{ backgroundColor: "white" }}>
    <Container style={{ height: "62vh" }}>
      <Row className="h-100">
        <Col style={{ alignSelf: "center", textAlign: "center" }}>
          <Image
            className="mr-auto"
            src={mainImage}
            height="200px"
            style={{ border: "1px solid #dedede" }}
            roundedCircle
          />
          <br />
          <h1>Matthias Göbel</h1>
          <h2>Mindful Mozart</h2>
        </Col>
      </Row>
      <Row style={{justifyContent: 'center'}} className="w-full  justify-center items-center">
       <iframe width="100%" height="400" style={{maxWidth: 956}} src="https://letscast.fm/podcasts/mindful-mozart-podcast-a0b6d027/iframe-player?size=l" frameborder="0" scrolling="no" tabindex="0"></iframe>
      </Row>
    </Container>
  </Container>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout path={frontmatter.path} language={frontmatter.language}>
      <IndexPageTemplate title={frontmatter.title} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title,
        path,
        language
      }
    }
  }
`;
